import { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { styled } from "styled-components";

import { appContext } from "../context/appContext";
import { useShareFileURL } from "../hooks/useShareFileURL";
import { FEEDBACK_FORM_URL } from "./constants/airtable";

const logo: string = require("../assets/mapc-logo.svg").default;

const FooterDiv = styled.div`
  width: 100vw;
  height: 7.5rem;
  background-color: rgba(0, 54, 94, 1);
  color: #fbfffe;
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 100vh;
`;

const FooterLeftDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2.5rem;
`;

const DetailsDiv = styled.div`
  color: #fbfffe;
  display: flex;
  flex-direction: row;
`;

const DetailsUl = styled.ul`
  list-style: none;
  padding-right: 2rem;
  padding-left: 0rem;
  margin-bottom: 0rem;
`;

const DetailsLi = styled.li`
  margin: 0px;
  text-decoration: none;
`;

const DetailLink = styled(Link)`
  color: #fbfffe;
`;

const DetailLinkDiv = styled.div`
  color: #fbfffe;
`;

const DetailAnchor = styled.a`
  text-decoration: none;
  color: #fbfffe;
`;

const FooterRightDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 2rem;
`;

const RightVertDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RightHorizDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const MAPCLogo = () => {
  return (
    <a href="https://mapc.org/" style={{ marginRight: "2.5rem" }}>
      <img src={logo} height={55} alt="MAPC Logo" />
    </a>
  );
};

const Footer = () => {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const shareFileURL = useShareFileURL(userSettings?.muni || "");
  return (
    <FooterDiv id={"footer"}>
      <FooterLeftDiv>
        <MAPCLogo />
        <DetailsDiv>
          <DetailsUl>
            <DetailsLi>
              <DetailAnchor href="https://goo.gl/maps/5GP7YcEtS1E2">
                <strong>60 Temple Place, Boston, MA 02111</strong>
              </DetailAnchor>
            </DetailsLi>
            <DetailsLi>
              <DetailAnchor href="tel:617-933-0700">617-933-0700</DetailAnchor>
            </DetailsLi>
            <DetailsLi>
              <DetailAnchor href="mailto:rentallistings@mapc.org">rentallistings@mapc.org</DetailAnchor>
            </DetailsLi>
          </DetailsUl>
        </DetailsDiv>
      </FooterLeftDiv>
      <FooterRightDiv>
        <DetailsDiv>
          <RightVertDiv>
            <RightHorizDiv>
              <DetailsUl>
                <DetailsLi>
                  <DetailLink to="/">
                    <DetailLinkDiv>Home</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
                {loginStatus && (
                  <DetailsLi>
                    <DetailLink target="_blank" to={shareFileURL}>
                      <DetailLinkDiv>ShareFile</DetailLinkDiv>
                    </DetailLink>
                  </DetailsLi>
                )}
                {loginStatus && (
                  <DetailsLi>
                    <DetailLink target="_blank" to={FEEDBACK_FORM_URL}>
                      <DetailLinkDiv>Bug/Feedback Form</DetailLinkDiv>
                    </DetailLink>
                  </DetailsLi>
                )}
              </DetailsUl>
              <DetailsUl>
                <DetailsLi>
                  <DetailLink to="/#about">
                    <DetailLinkDiv>About Us</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
                <DetailsLi>
                  <DetailLink to="/documentation">
                    <DetailLinkDiv>Documentation</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
                <DetailsLi>
                  <DetailLink to="/data">
                    <DetailLinkDiv>Data</DetailLinkDiv>
                  </DetailLink>
                </DetailsLi>
              </DetailsUl>
              {!loginStatus && (
                <DetailsUl>
                  <DetailsLi>
                    <DetailLink to="/login">
                      <DetailLinkDiv>Login</DetailLinkDiv>
                    </DetailLink>
                  </DetailsLi>
                  <DetailsLi>
                    <DetailLink to="/register">
                      <DetailLinkDiv>Register</DetailLinkDiv>
                    </DetailLink>
                  </DetailsLi>
                  <DetailsLi>
                    <DetailLink to="/password-reset-request">
                      <DetailLinkDiv>Forgot Password</DetailLinkDiv>
                    </DetailLink>
                  </DetailsLi>
                </DetailsUl>
              )}
            </RightHorizDiv>
          </RightVertDiv>
        </DetailsDiv>
      </FooterRightDiv>
    </FooterDiv>
  );
};

export default Footer;
