import React, { useState, useContext, useCallback, useEffect, useRef, useMemo } from "react";

import { useNavigate } from "react-router";

import { useDebounce } from "use-debounce";

import { styled } from "styled-components";

import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tab, Tabs } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "react-bootstrap/Accordion";

import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  Row as TRow,
  sortingFns,
  FilterFn,
  FilterFns,
  SortingFn,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { RankingInfo, rankings, rankItem, compareItems } from "@tanstack/match-sorter-utils";

import ScatterChart from "../components/ScatterChart";
import Aggregates from "../components/Aggregates";
import MedianAskMonthChart from "../components/MedianByMonthChart";
import MedianByNeighborhoodChart from "../components/MedianByNeighborhood";
import MedianBedMonthChart from "../components/MedianBedMonthChart";
import NumListingsByMonth from "../components/NumListingsByMonth";
import { ModalSubmitButton } from "../containers/ModalContainer";

import { appContext } from "../context/appContext";
import { elementsArray, DataSortType } from "../../types/props";
import { memberObject, memberResponse, proxyResponse, proxyObject } from "../../types/response";

import { municipalities, dashboardURLs } from "../components/constants/munis";
import { neighborhoodsForMuni } from "../components/constants/neighborhoods";

// import update from "./api/update";
import { dataResponseType } from "../../types/response";

import { Search, CaretDownFill, CaretUpFill, ClipboardFill } from "react-bootstrap-icons";
import { env } from "process";
import DataMap from "../components/MapViz";

const DataDiv = styled.div`
  width: 100vw;
  /* height: calc(85vh + 7.5rem); */
  display: flex;
  flex-direction: column;
  background-color: #fbfffe;
  padding: 2rem 2rem;
`;

const DataFormDiv = styled(Accordion)`
  width: 100%;
  /*
  display: flex;
  flex-direction: column;
  */
`;

const DataForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Filters = styled(Accordion.Item)`
  margin-bottom: 1rem;
`;

const FiltersHeader = styled(Accordion.Header)`
  button {
    font-weight: bold;
  }

  button[aria-expanded="true"] {
    background-color: rgba(33, 37, 41, 0.03);
  }
`;

const FilterLabel = styled(Form.Label)`
  font-weight: bold;
`;

const FilterGroup = styled(Form.Group)`
  border-bottom: 1px solid #dedede;
`;

const DataTabularContainerDiv = styled.div`
  width: 100%;
  height: calc(37rem - 40px);
  overflow-y: scroll;
  position: relative;
`;

const DataTable = styled(Table)`
  width: 100%;
`;

const DataTabularContainerTopDiv = styled.div`
  width: 100%;
  padding: 0 2.75rem 0 1rem;
  background-color: #ddf4ff;
  border-radius: 5px 5px 0px 0px;
`;

const DataTabularContainerBodyDiv = styled.div`
  width: 100%;
  height: 80%;

  background-color: #b9e2ff;
  border-radius: 0px 0px 5px 5px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
  border-color: #b9e2ff;
  border-width: 0.4rem;

  padding: 1rem;

  overflow-y: scroll;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #68a4dd;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fbfffe;
    margin: 0.1rem
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e2f4ff;
    
  }
`;

const DataTabularList = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  color: #635c7b;
  padding: 1.5rem;
  justify-content: start;
  align-items: center;
`;

const DataTabularListItem = styled(DataTabularList)`
  background-color: #fbfffe;
  margin-bottom: 0.65rem;
  &:hover {
    background-color: #ddf4ff;
  }
`;

const DataTabularListAlign = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;
  flex-grow: 1;
  justify-content: start;
  margin-right: 2rem;
`;

type styledWidthProps = {
  itemType?: string;
  top?: boolean;
};

const tabularSpacing = {
  title: "30rem",
  text: "12%",
  number: "12%",
};

const DataTabularListAlignItem = styled.div<styledWidthProps>`
  text-decoration: none;
  width: ${(props) => props.itemType};
  margin: 0 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;

  &:hover {
    color: ${(props) => props.top && "#13101f"};
  }
`;

const DataTabularListAlignLink = styled.a`
  text-decoration: none;
  color: #3e54d4;
  cursor: pointer;
`;

const DataInformation = styled.div`
  text-decoration: none;
  margin-top: 1rem;
`;

const DataVizContainer = styled.div`
  background-color: #ffffff;
  /*padding: 1rem;
  border-radius: 5px;
  border-style: solid;
  border-color: #e8f0ee;
  border-width: 2px;
  */
`;

const DataTabs = styled(Tabs)`
  button {
    font-size: 14px;
    font-weight: bold;
  }
`;

const DataProgressBar = styled(ProgressBar)`
  width: 100%;
  flex: 2;
`;

const StatusBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding-top: 1rem;

  border-top: 1px solid #dedede;

  p {
    margin-bottom: 0px;
  }
`;

const StyledTabContent = styled.div`
  height: 40rem;
  padding: 1rem;

  border-style: solid;
  border-color: #dee2e6;
  border-width: 1px;
  border-bottom-radius: 0.375rem;
  border-top: none;
`;

const minDate = new Date("12/01/2017");
const maxDate = new Date(Date.now());

// Sadly, JS Dates and HTML <input type="date"> are not straightforwardly compatible
const formatDate = (d) => {
  // Return YYYY-MM-DD date string
  const date = new Date(d);
  const dateString = date.toISOString();
  return dateString.slice(0, dateString.indexOf("T"));
};

//TData
type Listing = {
  title: string;
  ask: number;
  bedrooms: number;
  muni: string;
  neighborhood: string;
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, { threshold: rankings.CONTAINS });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const Data = () => {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);

  const parentRef = useRef<HTMLDivElement>(null);
  /*
  const rowVirtualizer = useVirtualizer({
    count: 10000,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
  });
  */

  // TODO: Pull this out into wrapper component
  const navigate = useNavigate();

  if (userSettings && !loginStatus) {
    navigate("/login");
  }

  const [tableData, setTableData] = React.useState<Listing[]>([]);
  // const columns: ColumnDef<Listing>[] = [];
  const columnHelper = createColumnHelper<Listing>();

  const columns = [
    columnHelper.accessor("title", {
      header: "Original Title",
      cell: (info) => info.getValue(),
      size: 5,
      minSize: 5,
      maxSize: 5,
      filterFn: "fuzzy",
      sortingFn: fuzzySort,
    }),
    columnHelper.accessor("ask", {
      header: "Ask Price",
      cell: (info) => `$${info.getValue()}`,
      size: 1,
      minSize: 1,
      maxSize: 1,
    }),
    columnHelper.accessor("bedrooms", {
      header: "Bedrooms",
      cell: (info) => info.getValue(),
      size: 1,
      minSize: 1,
      maxSize: 1,
    }),
    columnHelper.accessor("muni", {
      header: "Municipality",
      cell: (info) => info.getValue(),
      size: 1,
      minSize: 1,
      maxSize: 1,
    }),
    columnHelper.accessor("neighborhood", {
      header: "Neighborhood",
      cell: (info) => info.getValue(),
      size: 2,
      minSize: 2,
      maxSize: 2,
    }),
  ];

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [filterText] = useDebounce(globalFilter, 750);
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "title",
      desc: false,
    },
  ]);

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  });
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 21, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => parentRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1 ? (element) => element?.getBoundingClientRect().height : undefined,
    overscan: 20,
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[header.column.id] = header.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo]);

  const getSortMethod = useCallback(() => {
    if (sorting.length === 0) {
      return {
        sortSubject: null,
        sortType: null,
      };
    }
    return {
      sortSubject: sorting[0].id,
      sortType: sorting[0].desc ? "desc" : "asc",
    };
  }, [sorting]);

  // Visualization tabs
  const [activeTab, setActiveTab] = useState<string | null>("tabular");

  // filters
  const [ask, setAsk] = useState("");
  const [askFilter, setAskFilter] = useState("");
  const [numRooms, setNumRooms] = useState("");
  const [muni, setMuni] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [startDate, setStartDate] = useState<string>(formatDate(minDate));
  const [endDate, setEndDate] = useState<string>(formatDate(maxDate));
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [downloadType, setDownloadType] = useState("");

  const [data, setData] = useState<proxyObject[]>([]);
  const [totalNumItems, setTotalNumItems] = useState<number | null>(null);
  const [numItems, setNumItems] = useState<number>(0);
  const [dataElements, setDataElements] = useState<elementsArray>([]);
  const [url, setURL] = useState("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(true);

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "title") {
      if (table.getState().sorting[0]?.id !== "title") {
        table.setSorting([{ id: "title", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  useEffect(() => {
    if (!loaded && submitted && data.length === totalNumItems && totalNumItems != null) {
      setLoaded(true);
    }
  }, [data, totalNumItems]);

  const handleSubmit = async (e) => {
    // API call to get data, triggered on submit
    setData([]);
    setTableData([]);
    setSubmitted(true);
    setLoaded(false);
    setTotalNumItems(null);
    e.preventDefault();

    const chunkSize = 1000;

    const params = {
      ask,
      askFilter,
      numRooms,
      startDate,
      endDate,
      muni,
      neighborhood,
      chunkSize,
      searchTerm,
      offset: 0,
      sortMethod: getSortMethod(),
    };
     // make UI showing relevant data without changing the underlying data
     if (params.muni === "Cambridge") {
      switch (params.neighborhood) {
        case "West Cambridge":
          params.neighborhood = "Neighborhood 10";
          break;
        case "Baldwin":
          params.neighborhood = "Agassiz";
          break;
        case "The Port / Area IV":
          params.neighborhood = "Area IV";
          break;
        case "Wellington-Harrington":
          params.neighborhood = "Wellington Harrington";
          break;
        default:
          // do nothing
          break;
      }
    }
    try {
      const response = await fetch("/api/units", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });
      const { unitsList: initialListings } = await response.json();
      if (initialListings !== undefined) {
        let adjustlistings = changeNameForNeighborhood(initialListings)
        setData(adjustlistings);
        setTableData(
          adjustlistings.map((listing) => {
            return {
              title: listing.data.originalTitle,
              ask: listing.data.ask,
              bedrooms: listing.data.numRooms,
              muni: listing.data.muni,
              neighborhood: listing.data.neighborhood01,
            };
          })
        );
        setTotalNumItems(adjustlistings.length);
        setLoaded(true);
      }
    } catch (err) {
      console.error(err);
    }

  };

  const changeNameForNeighborhood = (initialListings) => {
    const adjustedListings = initialListings.map(listing => {
      if (listing.data.muni === "CAMBRIDGE") {
        switch (listing.data.neighborhood01) {
          case "Wellington Harrington":
            listing.data.neighborhood01 = "Wellington-Harrington";
            break;
    
          case "Area IV":
            listing.data.neighborhood01 = "The Port / Area IV";
            break;
    
          case "Agassiz":
            listing.data.neighborhood01 = "Baldwin";
            break;
    
          case "Neighborhood 10":
            listing.data.neighborhood01 = "West Cambridge";
            break;

          default:
            //do nothing
        }
      }
      return listing;  
    });
    

    return adjustedListings
      
  }
  const generateFileName = () => {
    // generate file name for data download
    const parts = ["RentalListings"];

    if (ask !== "") {
      parts.push(`${askFilter}${ask}`);
    }
    parts.push(numRooms);
    parts.push(startDate ? formatDate(startDate) : "");
    parts.push(endDate ? formatDate(endDate) : "");
    parts.push(muni.toUpperCase());
    parts.push(neighborhood);

    const sortMethod = getSortMethod();
    if (sortMethod.sortSubject != null) {
      parts.push(`${sortMethod.sortSubject}-${sortMethod.sortType}`);
    }

    if (searchTerm !== "") {
      parts.push(`search-${searchTerm}`);
    }

    return `${parts.filter((p) => p !== "").join("-")}${downloadType}`;
  };

  const download = (content, fileName, fileType) => {
    if (content === undefined) {
      return;
    }
    let fileToSave = new Blob(content, { type: fileType });
    const url = URL.createObjectURL(fileToSave);
    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.click();
  };

  const downloadData = async () => {
    // apply current filtering and download data
    const sortMethod = getSortMethod();

    const downloadArray = data.slice(0).map((d) => d.data);
    if (sortMethod.sortSubject != null) {
      downloadArray.sort((a, b) => {
        if (sortMethod.sortType === "asc") {
          if (a[sortMethod.sortSubject] < b[sortMethod.sortSubject]) {
            return -1;
          }
          if (a[sortMethod.sortSubject] > b[sortMethod.sortSubject]) {
            return 1;
          }
          return 0;
        } else if (sortMethod.sortType === "desc") {
          if (a[sortMethod.sortSubject] < b[sortMethod.sortSubject]) {
            return 1;
          }
          if (a[sortMethod.sortSubject] > b[sortMethod.sortSubject]) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    }

    if (downloadType === ".json") {
      download([JSON.stringify(downloadArray)], generateFileName(), "application/json");
    } else if (downloadType === ".csv") {
      const items = downloadArray;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")),
      ].join("\r\n");

      download([csv], generateFileName(), "text/csv;charset=utf-8");
    } else if (downloadType === ".shp.zip") {
      await fetch("/api/shapefile", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accepts: "application/zip, application/octet-stream",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.blob())
        .then((zipBlob) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(zipBlob);
          downloadLink.download = generateFileName();
          downloadLink.click();
        });
    } else if (downloadType === ".geojson") {
      // to GeoJSON.Point array
      const geoJSONPointArr = downloadArray.map((row) => {
        const geoJSON = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [row.longitude, row.latitude],
          },
          properties: row,
        };
        delete geoJSON.properties["location"];
        return geoJSON;
      });

      // to GeoJSON.FeatureCollection
      const pointArrFeatureCollection = {
        type: "FeatureCollection",
        features: geoJSONPointArr,
      };

      download([JSON.stringify(pointArrFeatureCollection)], generateFileName(), "application/geo+json");
    }
  };

  const dashboardLinks = (muni) => {
    const link = dashboardURLs[muni];
    return (
      <DataTabularListAlignLink href={link} target="_blank">
        <strong>Dashboard</strong>
      </DataTabularListAlignLink>
    );
  };

  const filters = {
    ask,
    askFilter,
    numRooms,
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    muni,
    neighborhood,
    searchTerm,
  };

  return (
    <div>
      <DataDiv>
        {loginStatus && (
          <DataFormDiv defaultActiveKey="0">
            <Filters eventKey="0">
              <FiltersHeader>Query</FiltersHeader>
              <Accordion.Body>
                <DataForm onSubmit={handleSubmit}>
                  <p>Get all listings where:</p>
                  <FilterGroup as={Row} className="mb-3">
                    <FilterLabel column sm="2">
                      Asking Price
                    </FilterLabel>
                    <Col sm="3">
                      <InputGroup className="mb-3">
                        <Form.Select
                          aria-label="Asking Filter"
                          onChange={(e) => {
                            setAskFilter(e.target.value);
                          }}
                          value={askFilter}
                          name="askFilter"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <option value="">is any amount</option>
                          <option value="eq">is equal to</option>
                          <option value="lt">is less than</option>
                          <option value="gt">is greater than</option>
                        </Form.Select>
                        {askFilter !== "" && <InputGroup.Text>$</InputGroup.Text>}
                        {askFilter !== "" && (
                          <Form.Control
                            type="text"
                            value={ask}
                            placeholder="dollar amount"
                            aria-label="dollar amount"
                            onChange={(e) => {
                              setAsk(e.target.value);
                            }}
                            name="ask"
                          />
                        )}
                      </InputGroup>
                    </Col>
                  </FilterGroup>
                  <FilterGroup as={Row} className="mb-3">
                    <FilterLabel column sm="2">
                      Number of Bedrooms
                    </FilterLabel>
                    <Col sm="3">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>is{numRooms !== "" ? " exactly" : ""}</InputGroup.Text>
                        <Form.Select
                          aria-label="Number(#) of Bedrooms"
                          onChange={(e) => {
                            setNumRooms(e.target.value);
                          }}
                          name="numRooms"
                          value={numRooms}
                        >
                          <option value="">any amount</option>
                          <option value="0">0 (Studio)</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </FilterGroup>
                  <FilterGroup as={Row} className="mb-3">
                    <FilterLabel column sm="2">
                      Date Posted
                    </FilterLabel>
                    <Col sm="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>is after</InputGroup.Text>
                        <Form.Control
                          type="date"
                          value={formatDate(startDate)}
                          onChange={(e) => {
                            try {
                              setStartDate(formatDate(e.target.value));
                            } catch (err) {
                              // Ignore: some inputs temporarily result in invalid date strings
                              // console.log("err", err);
                            }
                          }}
                        />
                        <InputGroup.Text>and before</InputGroup.Text>
                        <Form.Control
                          type="date"
                          value={formatDate(endDate)}
                          onChange={(e) => {
                            try {
                              setEndDate(formatDate(e.target.value));
                            } catch (err) {
                              // Ignore: some inputs temporarily result in invalid date strings
                              // console.log("err", err);
                            }
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </FilterGroup>
                  <FilterGroup as={Row} className="mb-3">
                    <FilterLabel column sm="2">
                      Municipality
                    </FilterLabel>
                    <Col sm="2">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>is</InputGroup.Text>
                        <Form.Select
                          aria-label="Municipality"
                          onChange={(e) => {
                            setMuni(e.target.value);
                          }}
                          name="muni"
                          value={muni}
                        >
                          <option value="">any municipality</option>
                          {municipalities.map((muni) => (
                            <option value={muni} key={`key-${muni}`}>
                              {muni}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </FilterGroup>
                  <FilterGroup as={Row} className="mb-3">
                    <FilterLabel column sm="2">
                      Neighborhood
                    </FilterLabel>
                    <Col sm="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>is</InputGroup.Text>
                        <Form.Select
                          aria-label="Neighborhood"
                          onChange={(e) => {
                            setNeighborhood(e.target.value);
                          }}
                          disabled={!municipalities.includes(muni)}
                          name="neighborhood"
                          value={neighborhood}
                        >
                          <option value="">any neighborhood{muni !== "" ? ` of ${muni}` : ""}</option>
                          {muni !== "" &&
                            neighborhoodsForMuni[muni].map((muni) => (
                              <option value={muni} key={`key-${muni}`}>
                                {muni}
                              </option>
                            ))}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </FilterGroup>
                  <FilterGroup as={Row} className="mb-3">
                    <FilterLabel column sm="2">
                      Title
                    </FilterLabel>
                    <Col sm="6">
                      <InputGroup className="mb-3">
                        <InputGroup.Text>contains</InputGroup.Text>
                        <Form.Control type="text" placeholder="any text" onChange={(e) => setSearchTerm(String(e.target.value))} value={searchTerm} />
                      </InputGroup>
                    </Col>
                  </FilterGroup>
                  <Row xs={5}>
                    <Col xs={1}>
                      <Button
                        variant="secondary"
                        className="mb-2"
                        style={{ height: "46px", width: "100%" }}
                        onClick={() => {
                          setAsk("");
                          setAskFilter("eq");
                          setNumRooms("");
                          setMuni("");
                          setNeighborhood("");
                          setStartDate(formatDate(minDate));
                          setEndDate(formatDate(maxDate));
                          setGlobalFilter("");
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <ModalSubmitButton style={{ height: "46px", width: "100%" }} isSubmitting={submitted && !loaded} label="Submit Query" />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="secondary"
                        className="mb-2"
                        style={{ height: "46px", width: "100%" }}
                        onClick={async () => {
                          // TODO
                          const params = {
                            ask,
                            askFilter,
                            numRooms: String(numRooms),
                            startDate,
                            endDate,
                            muni,
                            neighborhood,
                            searchTerm,
                            sortMethod: JSON.stringify(getSortMethod()),
                          };
                          const searchParams = new URLSearchParams(params);
                          const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api/units?${searchParams}`;

                          navigator.clipboard.writeText(url);
                        }}
                      >
                        Copy Query URL
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <StatusBar>
                      {!submitted && tableData.length === 0 && <p style={{ flex: 1 }}>No query submitted yet.</p>}
                      {!loaded && submitted && tableData.length === 0 && <p style={{ flex: 1 }}>Fetching rental listings...</p>}
                      {!loaded && submitted && tableData.length > 0 && totalNumItems != null && (
                        <p style={{ flex: 1 }}>
                          Loaded{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {tableData.length}/{totalNumItems}
                          </span>{" "}
                          listings matching current query.
                        </p>
                      )}
                      {loaded && submitted && tableData.length === totalNumItems && totalNumItems !== null && (
                        <p style={{ flex: 1 }}>
                          Loaded all <span style={{ fontWeight: "bold" }}>{totalNumItems}</span> listings matching current query.
                        </p>
                      )}
                      <DataProgressBar
                        animated={!loaded}
                        now={Math.ceil((tableData.length / (totalNumItems != null && totalNumItems > 0 ? totalNumItems : 100)) * 100)}
                        label={`${Math.ceil((tableData.length / (totalNumItems != null && totalNumItems > 0 ? totalNumItems : 100)) * 100)}%`}
                      />
                    </StatusBar>
                  </Row>
                </DataForm>
              </Accordion.Body>
            </Filters>
          </DataFormDiv>
        )}
        {!loginStatus && <div>Login to access data API</div>}
        {loginStatus && (
          <DataVizContainer>
            <DataTabs defaultActiveKey="tabular" fill activeKey={activeTab || "tabular"} onSelect={(t) => setActiveTab(t)}>
              <Tab eventKey="tabular" title="Tabular View">
                {activeTab === "tabular" && (
                  <StyledTabContent>
                    {submitted && tableData.length > 0 && (
                      <FilterGroup as={Row} className="mb-3">
                        <div style={{ display: "flex", marginBottom: "1rem", alignItems: "center", gap: "1rem" }}>
                          <InputGroup style={{ flex: 2 }}>
                            <InputGroup.Text>
                              <Search />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder={``} onChange={(e) => setGlobalFilter(String(e.target.value))} value={globalFilter ?? ""} />
                          </InputGroup>
                          <p style={{ flex: 3, marginBottom: "0px" }}>
                            Showing{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {table.getFilteredRowModel().rows.length}/{tableData.length}
                            </span>{" "}
                            rows{globalFilter !== "" ? " matching search text" : ""}.
                          </p>
                        </div>
                      </FilterGroup>
                    )}
                    <DataTabularContainerDiv ref={parentRef}>
                      {!submitted && tableData.length === 0 && (
                        <p style={{ marginTop: "15rem", textAlign: "center" }}>Submit a query to start exploring rental listings data.</p>
                      )}
                      {submitted && loaded && tableData.length === 0 && <p>No listings found matching those filters.</p>}
                      {submitted && (
                        <DataTable hover bordered>
                          <thead
                            style={{
                              position: "sticky",
                              insetBlockStart: 0,
                              zIndex: 1,
                              padding: "0.5rem",
                              backgroundColor: "white",
                            }}
                          >
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id} style={{ display: "flex", width: "100%" }}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      style={{
                                        display: "flex",
                                        flex: columnSizeVars[header.column.id],
                                        backgroundColor: "rgba(33, 37, 41, 0.03)",
                                      }}
                                    >
                                      <div
                                        style={{ cursor: header.column.getCanSort() ? "pointer" : "initial", userSelect: "none" }}
                                        onClick={header.column.getToggleSortingHandler()}
                                      >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                          asc: <CaretUpFill />,
                                          desc: <CaretDownFill />,
                                        }[header.column.getIsSorted() as string] ?? null}
                                      </div>
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>
                          <tbody
                            style={{
                              display: "grid",
                              height: `${rowVirtualizer.getTotalSize()}px`,
                              position: "relative",
                            }}
                          >
                            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                              const row = rows[virtualRow.index] as TRow<Listing>;
                              return (
                                <tr
                                  data-index={virtualRow.index} //needed for dynamic row height measurement
                                  ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                                  key={row.id}
                                  style={{
                                    display: "flex",
                                    position: "absolute",
                                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                                    width: "100%",
                                  }}
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <td
                                        key={cell.id}
                                        style={{
                                          display: "flex",
                                          flex: columnSizeVars[cell.column.id],
                                        }}
                                      >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </DataTable>
                      )}
                    </DataTabularContainerDiv>
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="aggregates" title="Aggregates">
                {activeTab === "aggregates" && (
                  <StyledTabContent>
                    <Aggregates chartData={data} filters={filters} numItems={data.length} />
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="map" title="Map">
                {activeTab === "map" && (
                  <StyledTabContent>
                    {userSettings !== null && <DataMap chartData={data} defaultMuni={muni !== "" && muni != null ? muni : userSettings?.muni} />}
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="singleAxis" title="Single Axis Scatter Plot">
                {activeTab === "singleAxis" && (
                  <StyledTabContent>
                    {userSettings !== null && (
                      <ScatterChart chartData={data} filters={filters} defaultMuni={muni !== "" && muni != null ? muni : userSettings?.muni} />
                    )}
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="medianAskMonth" title="Median Ask by Month">
                {activeTab === "medianAskMonth" && (
                  <StyledTabContent>
                    {userSettings !== null && (
                      <MedianAskMonthChart chartData={data} filters={filters} defaultMuni={muni !== "" && muni != null ? muni : userSettings?.muni} />
                    )}
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="timeseries" title="Median by Bedroom by Month">
                {activeTab === "timeseries" && (
                  <StyledTabContent>
                    {userSettings !== null && (
                      <MedianBedMonthChart chartData={data} filters={filters} defaultMuni={muni !== "" && muni != null ? muni : userSettings?.muni} />
                    )}
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="medianByNeighborhood" title="Median Ask by Neighborhood">
                {activeTab === "medianByNeighborhood" && (
                  <StyledTabContent>
                    {userSettings !== null && (
                      <MedianByNeighborhoodChart chartData={data} filters={filters} defaultMuni={muni !== "" && muni != null ? muni : userSettings?.muni} />
                    )}
                  </StyledTabContent>
                )}
              </Tab>
              <Tab eventKey="listingsByMonth" title="Num Listings by Month">
                {activeTab === "listingsByMonth" && (
                  <StyledTabContent>
                    {userSettings !== null && (
                      <NumListingsByMonth chartData={data} filters={filters} defaultMuni={muni !== "" && muni != null ? muni : userSettings?.muni} />
                    )}
                  </StyledTabContent>
                )}
              </Tab>
            </DataTabs>

            <DataInformation>
              <strong>To download this data: </strong>
              <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Form.Select
                  aria-label="Download Type"
                  onChange={(e) => {
                    setDownloadType(e.target.value);
                  }}
                  name="Download Type"
                  style={{ width: "12rem" }}
                >
                  <option value="">Choose a file type</option>
                  <option value=".json">{"JSON"}</option>
                  <option value=".csv">{"CSV"}</option>
                  <option value=".shp.zip">{"Shapefile (zipped)"}</option>
                  <option value=".geojson">{"GeoJSON"}</option>
                </Form.Select>
                <DataTabularListAlignLink>
                  <Button
                    disabled={downloadType === ""}
                    onClick={() => {
                      downloadData();
                    }}
                  >
                    Download
                  </Button>
                </DataTabularListAlignLink>
              </div>
            </DataInformation>
          </DataVizContainer>
        )}
      </DataDiv>
    </div>
  );
};

export default Data;
