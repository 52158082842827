import { useState, useEffect } from 'react';

export const useShareFileURL = (muni) => {
  const [links, setLinks] = useState({});
  useEffect(() => {
    if (Object.keys(links).length === 0) {
      const fetchData = async () => {
        await fetch("/api/links", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data: linksResponse) => {
            if (data !== undefined) {
              const linkObject = {};
              data["links"].forEach((element) => {
                linkObject[element.Muni] = element.currentLink;
              });
              setLinks(linkObject);
            }
          });
      };

      fetchData();
    }
  }, [links]);
  const shareFileURL = links[muni] || "";
  return shareFileURL;
}

