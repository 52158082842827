import { useState, useCallback, useContext, useEffect, FC } from "react";
import { Link } from "react-router-dom";

import { styled } from "styled-components";
import { Carousel, ButtonProps } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import { appContext } from "../context/appContext";
import { useShareFileURL } from "../hooks/useShareFileURL";
import { noLogProps } from "../../types/props";
import { linksObject, linksResponse } from "../../types/response";

const bedroomCountImg = require("../assets/bedroom_count_monthlytimeline_citywise.avif");
const bedroomRentImg = require("../assets/bedroom_rent_monthlytimeline_mapc.avif");
const rentSpreadImg = require("../assets/rental_listings_spread_muni.avif");

type StyledProps = {
  login: boolean;
};

const ParticlesHero = styled(Particles)`
  position: absolute;
  width: 100vw;
  height: 40rem;
  pointer-events: none;
`;

const HeroDiv = styled.div`
  width: 100vw;
  height: 40rem;
  display: flex;
  flex-direction: column;
`;

const HeroContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0px;
  font-size: 36px;

  z-index: 5;
`;

const HeroCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 2rem;

  color: #635c7b;

  z-index: 5;
`;

const HeroTitle = styled.h2`
  margin-bottom: 1.5rem;

  font-size: 24px;
  text-align: center;
  font-weight: bold;
`;

const HeroButton: FC<ButtonProps> = styled(Button)`
  background: #219af1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #0c70b8 !important;
  }
  &:active {
    background-color: #084e81 !important;
  }
`;

const CarouselContainer = styled.div`
  width: 55vw;
  height: 30rem;
  background-color: #ffffff;
  border-radius: 0.375rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #2b3b5e;
  font-size: 20px;
`;

const HeroCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
`;

const CarouselTitle = styled.h2`
  margin-bottom: 1.75rem;
  color: #635c7b;
`;

const CarouselImg = styled.img`
  display: block;
  width: auto;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #635c7b;
`;

const CallToActionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  font-size: 16px;
`;

const Hero = () => {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const shareFileURL = useShareFileURL(userSettings?.muni || "");

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <HeroDiv>
      <ParticlesHero
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: { enable: false },
          fpsLimit: 60,
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 4 },
            },
          },
        }}
      ></ParticlesHero>
      <HeroContainer>
        {loginStatus ? (
          <HeroCard>
            <HeroTitle>Download the Newest Data</HeroTitle>
            <HeroButton href={shareFileURL} target="_blank">
              View in ShareFile
            </HeroButton>
          </HeroCard>
        ) : (
          <>
            <HeroCard>
              <CarouselContainer>
                <CarouselTitle>Data Visualizations</CarouselTitle>
                <HeroCarousel variant="dark">
                  <Carousel.Item>
                    <CarouselImg src={bedroomCountImg} alt="" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <CarouselImg src={bedroomRentImg} alt="" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <CarouselImg src={rentSpreadImg} alt="" />
                  </Carousel.Item>
                </HeroCarousel>
              </CarouselContainer>
              <CallToActionDiv>
                <span>Interested in seeing more?</span>
                <HeroButton href="/register">Join Us</HeroButton>
              </CallToActionDiv>
            </HeroCard>
          </>
        )}
      </HeroContainer>
    </HeroDiv>
  );
};

export default Hero;
