const boston_neighborhoods = [
  "Roslindale",
  "Jamaica Plain",
  "Mission Hill",
  "Longwood",
  "Bay Village",
  "Leather District",
  "Chinatown",
  "North End",
  "Roxbury",
  "South End",
  "Back Bay",
  "East Boston",
  "Charlestown",
  "West End",
  "Beacon Hill",
  "Downtown",
  "Fenway",
  "Brighton",
  "West Roxbury",
  "Hyde Park",
  "Mattapan",
  "Dorchester",
  "South Boston Waterfront",
  "South Boston",
  "Allston",
  "Harbor Islands",
];

const cambridge_neighborhoods = [
  "The Port / Area IV",
  "Neighborhood Nine",
  "Wellington-Harrington",
  "Mid-Cambridge",
  "North Cambridge",
  "Cambridge Highlands",
  "Strawberry Hill",
  "West Cambridge",
  "Riverside",
  "Cambridgeport",
  "Area 2/MIT",
  "East Cambridge",
  "Baldwin",
];

const somerville_neighborhoods = [
  "North Point",
  "Boynton Yards",
  "Twin City",
  "Brickbottom",
  "Duck Village",
  "Inner Belt",
  "Union Square",
  "East Somerville",
  "Porter Square",
  "Spring Hill",
  "Assembly Square",
  "Ten Hills",
  "Magoun Square",
  "Winter Hill",
  "Ball Square",
  "Davis Square",
  "Powder House Square",
  "Teele Square",
  "Hillside",
];

const quincy_neighborhoods = [
  "Germantown",
  "Houghs Neck",
  "Marina Bay",
  "NA",
  "North Quincy",
  "Quincy Center",
  "Quincy Point",
  "South Quincy",
  "Squantum",
  "West Quincy",
  "Wollaston",
  "",
];

const arlington_neighborhoods = ["25017356100", "25017356300", "25017356400", "25017356500", "25017356601", "25017356602", "25017356701", "25017356702"];

const neighborhoodsForMuni = {
  Boston: boston_neighborhoods,
  Cambridge: cambridge_neighborhoods,
  Somerville: somerville_neighborhoods,
  Quincy: quincy_neighborhoods,
  Arlington: arlington_neighborhoods,
};

export { boston_neighborhoods, cambridge_neighborhoods, somerville_neighborhoods, quincy_neighborhoods, arlington_neighborhoods, neighborhoodsForMuni };
