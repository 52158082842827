import { useEffect, useState } from "react";

import { styled } from "styled-components";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Spinner } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import { UpdateType, elementsArray, UpdatesArray } from "../../types/props";

import { UserData } from "../../types/auth";

const AboutDiv = styled.div`
  width: 100vw;
  height: 37.5rem;
  background-color: #fbfffe;
  color: #635c7b;
  display: flex;
  padding: 1rem 3rem;
  flex-direction: row;
  border-bottom: 1px solid #dedede;
`;
const AboutLeftDiv = styled.div`
  flex: 1;
  padding: 1rem 2rem;
`;
const AboutRightDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
`;
const AboutTitle = styled.h2`
  color: #635c7b;
  margin-bottom: 1.5rem;
  font-size: 24px;
`;
const AboutText = styled.div`
  color: #635c7b;
  font-size: 16px;
  line-height: 1.75rem;
`;

const UpdateTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: #635c7b;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const LogBody = styled.div`
  color: #635c7b;
`;

const UpdateList = styled(ListGroup)`
  max-height: 450px;
  overflow-y: scroll;
`;

type PropType = {
  loginStatus: boolean;
};

const logs = [
  {
    id: 0,
    Body: "Data Downloads: Multiple avenues for downloading our 80,000+ Rental Listings data records",
    Status: "Published",
    Date: new Date(Date.now()).toLocaleDateString(),
  },
  {
    id: 1,
    Body: "Data API Access: Gain access to our data API!",
    Status: "Published",
    Date: new Date(Date.now()).toLocaleDateString(),
  },
  {
    id: 2,
    Body: "Data Visualization: View your data in app, with more visualizations coming soon!",
    Status: "Published",
    Date: new Date(Date.now()).toLocaleDateString(),
  },
];

const About = ({ loginStatus }: PropType) => {
  const [updates, setUpdates] = useState<UpdateType[]>(loginStatus ? [] : logs);
  const [fetchedUpdates, setFetchedUpdates] = useState<boolean>(false);

  const [aboutContent, setAboutContent] = useState<any | null>(null);
  const [fetchedAbout, setFetchedAbout] = useState<boolean>(false);

  useEffect(() => {
    if (!loginStatus) {
      setUpdates(logs);
    } else if (loginStatus && !fetchedUpdates) {
      const fetchData = async () => {
        await fetch("/api/updates", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data !== undefined) {
              setUpdates(data["update"].filter(u => u.Date != null));
            }
          });
      };

      fetchData();
      setFetchedUpdates(true);
    }
  }, [fetchedUpdates, loginStatus]);

  useEffect(() => {
    if (!fetchedAbout) {
      const fetchData = async () => {
        await fetch("/api/about", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data !== undefined && data.ok) {
              setAboutContent(data.record.fields);
            }
            setFetchedAbout(true);
          });
      };

      fetchData();
    }
  }, [fetchedAbout]);

  return (
    <AboutDiv>
      <AboutLeftDiv>
        {aboutContent && (
          <AboutTitle id="about">
            <Markdown remarkPlugins={[remarkGfm]}>{aboutContent.Title}</Markdown>
          </AboutTitle>
        )}
        {aboutContent && (
          <AboutText>
            <Markdown remarkPlugins={[remarkGfm]}>{aboutContent.Body}</Markdown>
          </AboutText>
        )}
      </AboutLeftDiv>
      <AboutRightDiv>
        <AboutTitle>{`${loginStatus ? "Update Log" : "Become a Member and get access to:"}`}</AboutTitle>
        {loginStatus && updates.length === 0 && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <UpdateList>
          {updates
            .filter((u) => u.Status === "Published")
            .map((update) => (
              <ListGroup.Item key={update.id}>
                {loginStatus && <UpdateTitle>{new Date(update.Date).toLocaleDateString()}</UpdateTitle>}
                <LogBody>{update.Body}</LogBody>
              </ListGroup.Item>
            ))}
        </UpdateList>
        {!loginStatus && (
          <iframe
            style={{ alignSelf: "center", marginTop: "1.5rem", border: "1px solid #dee2e6", borderRadius: "0.5rem" }}
            src="https://slides.com/mapc/deck-7f8e82/embed?style=light&byline=hidden&share=hidden"
            width="576"
            height="420"
            title="Metro Boston Rental Listings Collaboration"
            scrolling="no"
          ></iframe>
        )}
      </AboutRightDiv>
    </AboutDiv>
  );
};

export default About;
