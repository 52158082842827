import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import React from "react";

import { Form, Dropdown } from "react-bootstrap";

import type { EChartsOption, SetOptionOpts } from "echarts";

import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";

import municipalities from "./constants/munis";
import { DataFilterType } from "../../types/props";
import { priceFormat } from "./utils/converter";

export const ScatterChart = ({ chartData, filters, defaultMuni = "" }: { chartData: proxyObject[]; filters?: DataFilterType; defaultMuni: string }) => {
  const [askCount, setAskCount] = useState({}); // {askValue: {muni (UPPERCASE) : count of ASK value given muni}}
  const [axis, setAxis] = useState<Number[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [option, setOption] = useState<EChartsOption>({});
  const [sizeMap, setSizeMap] = useState({}); // map count values to a size to represent
  const [selectedMunis, setSelectedMunis] = useState<string[]>(defaultMuni !== "" && defaultMuni != null ? [defaultMuni] : municipalities);

  useEffect(() => {
    const defineAxis = () => {
      const axisArray: Number[] = [];
      let maxValue = -1;
      let minValue = Infinity;

      let tempObj = {};

      chartData.map((element) => {
        maxValue = Math.max(element.data.ask, maxValue);
        minValue = Math.min(element.data.ask, minValue);

        const tempMuni = element.data.muni;

        if (tempObj[element.data.ask] === undefined) {
          // If tempObj[element.data.ask] is undefined, initialize it as an empty object
          tempObj[element.data.ask] = {};
        }

        if (tempObj[element.data.ask][tempMuni] === undefined) {
          if (filters?.askFilter === "eq" && element.data.ask !== Number(filters.ask) && isNaN(parseFloat(filters?.ask)) !== true) {
            return false;
          }
          tempObj[element.data.ask][tempMuni] = 1;
        } else {
          tempObj[element.data.ask][tempMuni] += 1;
        }
      });

      setAskCount(tempObj);

      if (filters !== undefined && isNaN(parseFloat(filters?.ask))) return [minValue, maxValue];

      if (filters?.askFilter === "eq") {
        return [maxValue, maxValue];
      } else if (filters?.askFilter === "lt") {
        return [0, Number(filters.ask)];
      } else if (filters?.askFilter === "gt") {
        return [Number(filters.ask), maxValue];
      }
      return axisArray;
    };

    setAxis(defineAxis());
  }, [chartData, filters]);

  useEffect(() => {
    const setupBins = () => {
      // parses through ask value count in AskCount object, and normalizes to a size of SIZE
      const tempDict = {};

      let maxCount = 0;
      Object.values(askCount).forEach((muniCount: object) => {
        Object.values(muniCount).forEach((counts: number) => {
          maxCount = Math.max(maxCount, counts);
        });
      });

      const normalizeSize = 35;
      const binInterval = normalizeSize / maxCount;
      Object.values(askCount).forEach((muniCount: object) => {
        Object.values(muniCount).forEach((counts: number) => {
          tempDict[counts] = Math.floor(counts * binInterval) + 1;
        });
      });

      return tempDict;
    };
    setSizeMap(setupBins());
  }, [askCount]);

  useEffect(() => {
    if (Object.keys(sizeMap).length > 0 && axis.length > 0) {
      const muniDict = { Arlington: 0, Boston: 1, Cambridge: 2, Quincy: 3, Somerville: 4 };

      let title: echarts.TitleComponentOption[] = [];
      let singleAxis: echarts.SingleAxisComponentOption[] = [];
      let series: echarts.ScatterSeriesOption[] = [];

      selectedMunis.forEach((muni, idx) => {
        title.push({
          textBaseline: "middle",
          top:
            ((idx + 0.5) * 115) / (selectedMunis.length + (2 / Object.keys(muniDict).length) * selectedMunis.length) +
            (municipalities.length - selectedMunis.length + 2.5) * 1.5 +
            "%",
          text: muni,
        });

        if (filters?.askFilter === "eq" && isNaN(parseFloat(filters?.ask)) !== true) {
          singleAxis.push({
            name: "Asking Price",
            nameLocation: "middle",
            nameTextStyle: {
              align: "center",
              verticalAlign: "top",
              padding: [10, 0, 0, 0],
            },
            left: 150,
            type: "category",
            data: [filters.ask],
            top:
              (idx * 115) / (selectedMunis.length + (2 / Object.keys(muniDict).length) * selectedMunis.length) +
              (municipalities.length - selectedMunis.length + 3) * 4.75 +
              "%",
            height:
              100 / (selectedMunis.length + (2 / Object.keys(muniDict).length) * selectedMunis.length) -
              (municipalities.length - selectedMunis.length + 3) * 5.5 +
              "%",
          });
        } else {
          singleAxis.push({
            name: "Asking Price",
            nameLocation: "middle",
            nameTextStyle: {
              align: "center",
              verticalAlign: "top",
              padding: [10, 0, 0, 0],
            },
            left: 150,
            type: "value",
            min: axis[0] as any,
            max: axis[1] as any,
            top:
              (idx * 115) / (selectedMunis.length + (2 / Object.keys(muniDict).length) * selectedMunis.length) +
              (municipalities.length - selectedMunis.length + 3) * 4.75 +
              "%",
            height:
              100 / (selectedMunis.length + (2 / Object.keys(muniDict).length) * selectedMunis.length) -
              (municipalities.length - selectedMunis.length + 3) * 5.5 +
              "%",
          });
        }
        series.push({
          singleAxisIndex: idx,
          coordinateSystem: "singleAxis",
          name: muni,
          type: "scatter",
          data: [],
          symbolSize: (dataItem) => {
            return sizeMap[dataItem[1]] * 3;
          },
        });
      });

      if (askCount !== undefined) {
        Object.keys(askCount).forEach((dataItem) => {
          selectedMunis.forEach((muni) => {
            if (askCount[dataItem][muni.toUpperCase()] !== null) {
              (series as any)[selectedMunis.indexOf(muni)].data.push([Number(dataItem), askCount[dataItem][muni.toUpperCase()]]);
            }
          });
        });
      }

      setOption({
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        tooltip: {
          position: "top",
          formatter: (params) => {
            const [askPrice, countOfListings] = params.value;
            return `<b>Asking price:</b> ${priceFormat.format(askPrice)} <br/> <b>Number of listings:</b> ${countOfListings}`;
          }
        },
        title: title,
        singleAxis: singleAxis,
        series: series,
        responsive: true,
        maintainAspectRatio: false,
      });
      setLoaded(true);
    }
  }, [selectedMunis, loaded, sizeMap, axis]);

  const resetChart = () => {
    setOption({
      ...option,
      title: [],
      singleAxis: [],
      series: [],
    });
  };

  const handleCheck = (select: string) => {
    resetChart();
    const tempSelect = [...selectedMunis];

    if (tempSelect.includes(select)) {
      tempSelect.splice(tempSelect.indexOf(select), 1);
    } else {
      tempSelect.push(select);
    }

    setSelectedMunis(tempSelect);
  };

  return (
    <>
      <ReactECharts option={option} />
      <Dropdown style={{ top: "-1rem" }}>
        <Dropdown.Toggle id="dropdown-basic">Choose Municipalities</Dropdown.Toggle>

        <Dropdown.Menu>
          <Form>
            {municipalities.map((muni) => (
              <Dropdown.Item
                key={`${muni}`}
                className="mb-3"
                id={`${muni}`}
                onClick={() => {
                  handleCheck(muni);
                }}
                active={selectedMunis.includes(muni)}
              >
                {muni}
              </Dropdown.Item>
            ))}
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ScatterChart;
