import { styled } from "styled-components";

const cambridgeImg = require("../assets/feedbackCarousel/cityseal_cambridge.avif");
const bostonImg = require("../assets/feedbackCarousel/cityseal_boston.avif");
const quincyImg = require("../assets/feedbackCarousel/cityseal_quincy.avif");
const scottWalkerImg = require("../assets/feedbackCarousel/scottWalker.avif");
const sherryZouImg = require("../assets/feedbackCarousel/sherryZou.avif");
const ameliaNajjar = require("../assets/feedbackCarousel/ameliaNajjar.avif");

const FeedbackWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  height: fit-content;
  width: 100vw;
  margin: 0 auto;
  background-color: #fbfffe;
`;

const Card = styled.div`
  position: relative;
  width: 450px;
  height: 350px;
  padding: 1rem;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 30px -1px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  background-color: #f8f9fa;
  margin-top: 120px;
  margin-bottom: 2rem;

  &::before {
    content: "";
    position: absolute;
    top: -80px; /* Lower the roof */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 225px solid transparent; /* Adjust to make steeper */
    border-right: 225px solid transparent;
    border-bottom: 80px solid rgba(0, 54, 94, 1);
  }

  /* Chimney */
  &::after {
    content: "";
    position: absolute;
    top: -90px; /* Position above the roof */
    left: 60%; /* Position towards the right */
    width: 30px; /* Chimney width */
    height: 50px; /* Chimney height */
    background-color: rgba(0, 54, 94, 1);
    border-radius: 5px;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 54, 94, 1);
`;

const FeedbackText = styled.p`
  font-size: 14px;
  font-style: italic;
  color: #635c7b;
  margin-bottom: 15px;
  height: 135px;
`;

/* Flex container for company logo, name, and position */
const UserInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const CompanyLogo = styled.img`
  width: 50px;
  margin-left: -50px;
`;

const UserName = styled.p`
  font-weight: bold;
  margin: 0px;
  color: #635c7b;
`;

const UserPosition = styled.p`
  font-size: 12px;
  color: #635c7b;
  margin: 0px;
`;

const cards = [
  {
    avatar: scottWalkerImg,
    avatarAltText: "Headshot for Scott Walker",
    feedback: (
      <>
        "...we have made extensive use of the work of MAPC's Rental Listings Database to inform program and policy discussions internally and for the public.{" "}
        ...The new website makes it easy for non-technical users to access useful analysis and generate their own specialized reports."
      </>
    ),
    companyLogo: cambridgeImg,
    companyLogoAltText: "The city seal for Cambridge",
    name: "Scott Walker",
    position: "Senior Manager for Data Services, City of Cambridge",
  },
  {
    avatar: sherryZouImg,
    avatarAltText: "Headshot for Sherry Zou",
    feedback: (
      <>
        "I used the website to identify real average rents in Quincy for the affordable housing trust's determination of their inclusionary housing rent limit.
        ...The website provided a more accurate number for the AHT to benchmark compared to the HUD-determined Fair-Market-Rent rate."
      </>
    ),
    companyLogo: quincyImg,
    companyLogoAltText: "The city seal for Quincy",
    name: "Sherry Zou",
    position: "Housing Programs Manager, City of Quincy",
  },
  {
    avatar: ameliaNajjar,
    avatarAltText: "Headshot for Amelia Najjar",
    feedback: (
      <>
        "MAPC provides substantial technical support, and it's helpful to discuss trends in Greater Boston with peer cities. The City of Boston has used the
        data to explore the percentage of listings that are affordable to different income levels, track neighborhood trends, and help with an analysis of Small
        Area Fair Market Rents."
      </>
    ),
    companyLogo: bostonImg,
    companyLogoAltText: "The city seal for Boston",
    name: "Amelia Najjar",
    position: "Senior Research Analyst, City of Boston",
  },
];

const FeedbackCarousel = () => {
  return (
    <FeedbackWrapper>
      {cards.map((card, index) => (
        <Card key={index}>
          <Avatar src={card.avatar} alt={card.avatarAltText} />
          <FeedbackText>{card.feedback}</FeedbackText>
          <UserInfoRow>
            <CompanyLogo src={card.companyLogo} alt={card.companyLogoAltText} />
            <div>
              <UserName>{card.name}</UserName>
              <UserPosition>{card.position}</UserPosition>
            </div>
          </UserInfoRow>
        </Card>
      ))}
    </FeedbackWrapper>
  );
};

export default FeedbackCarousel;
